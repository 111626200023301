<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="驾校名称" prop="name" >
        <a-input v-model="form.name" placeholder="请输入驾校名称" />
      </a-form-model-item>
      <a-form-model-item label="校区权限" prop="deptId" >
        <a-select :allowClear=true placeholder="请选择校区权限" v-model="form.deptId">
          <a-select-option v-for="(d, index) in schoolDeptList" :key="index" :value="d.value" >{{ d.label }}</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="区域"  >
        <a-select placeholder="省" @change="subjectLevelOneChanged1" v-model="provinceId">
          <a-select-option v-for="(item, key) in citydata" :key="key" :value="item.id" >
                <span style="display: inline-block;width: 30%" :title=" item.name  ">
                  {{ item.name }}
                </span>
          </a-select-option>
        </a-select>

        <a-select placeholder="市" v-model="cityId" @change="subjectLevelTwoChanged1">
          <a-select-option v-for="(item, key) in subjectTwoList" :key="key" :value="item.id" >
                <span style="display: inline-block;width: 100%" :title=" item.name  ">
                  {{ item.name  }}
                </span>
          </a-select-option>
        </a-select>

        <a-select placeholder="区" v-model="areaId">
          <a-select-option v-for="(item, key) in subjectThreeList" :key="key" :value="item.id" >
                <span style="display: inline-block;width: 100%" :title=" item.name  ">
                  {{ item.name  }}
                </span>
          </a-select-option>
        </a-select>


<!--        <el-cascader-->
<!--            v-model="form.area"-->
<!--            :options="citydata"-->
<!--            :props="{-->
<!--            expandTrigger: 'hover',-->
<!--            children: 'children',-->
<!--            label: 'name',-->
<!--            value: 'id',-->
<!--            checkStrictly:'true'-->
<!--           }"-->
<!--            :show-all-levels="true"-->
<!--          ></el-cascader>-->
<!--        <a-input v-model="form.area" placeholder="请输入驾校名称" />-->
      </a-form-model-item>

      <a-form-model-item label="主图" prop="pic" >
        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          :showUploadList="false"
          :beforeUpload="beforeUpload"
          :customRequest="handleChange"
          v-model="form.imgUrl"
        >
          <img v-if="form.pic" :src="form.pic" alt="轮播图"
               style="height:104px;max-width:300px"/>
          <div v-else>
            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>

      <a-form-model-item  :labelCol="labelCol" :wrapperCol="wrapperCol"  style="width: 30%">
        <MappModel ref="MappModel" @ok="tencentMapOk"></MappModel>
      </a-form-model-item>

      <a-form-model-item label="地址" prop="address" >
        <a-input v-model="form.address" placeholder="请输入地址" disabled='true'/>
      </a-form-model-item>
      <a-form-model-item label="经度" prop="lon" >
        <a-input v-model="form.lon" placeholder="请输入经度" disabled='true'/>
      </a-form-model-item>
      <a-form-model-item label="维度" prop="lat" >
        <a-input v-model="form.lat" placeholder="请输入维度" disabled='true'/>
      </a-form-model-item>

<!--      <a-form-item-->
<!--        :labelCol="labelCol"-->
<!--        :wrapperCol="wrapperCol"-->
<!--        label="店铺的经纬度">-->
<!--        <a-input v-model="this.lnglat" contentEditable="false"/>-->
<!--      </a-form-item>-->
<!--      <a-form-item-->
<!--        :labelCol="labelCol"-->
<!--        :wrapperCol="wrapperCol"-->
<!--        label="店铺详细地址">-->
<!--        <a-textarea placeholder="请输入店铺详细地址" v-model="this.address"   ></a-textarea>-->
<!--      </a-form-item>-->



      <a-form-model-item label="介绍" prop="introduce" >
        <a-input v-model="form.introduce" placeholder="请输入介绍" />
      </a-form-model-item>
      <a-form-model-item label="联系电话" prop="phone" >
        <a-input v-model="form.phone" placeholder="请输入联系电话" />
      </a-form-model-item>
      <a-form-model-item label="官网" prop="website" >
        <a-input v-model="form.website" placeholder="请输入官网" />
      </a-form-model-item>
      <a-form-model-item label="邮箱" prop="mail" >
        <a-input v-model="form.mail" placeholder="请输入邮箱" />
      </a-form-model-item>
      <a-form-model-item label="工作时间" prop="workTime" >
        <a-input v-model="form.workTime" placeholder="请输入工作时间" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getCampus, addCampus, updateCampus,listSchoolDept,dataCity } from '@/api/driving-school/campus'
import ossUpload from "@/utils/ossUpload";
import MappModel from './MappModel.vue';

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    MappModel
  },
  created() {
    this.getCityData()
    this.getSchoolDept()
  },
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      lng: '',
      lat: '',
      address: '',
      schoolDeptList: [],
      citydata: null,
      subjectTwoList:[],
      subjectThreeList:[],
      submitLoading: false,
      formTitle: '',
      uploadLoading: false,
      areaId: null,
      provinceId:null,
      cityId:null,
      // 表单参数
      form: {
        id: null,

        name: null,

        address: null,

        pic: null,

        lon: null,

        lat: null,

        introduce: null,

        phone: null,

        createTime: null,

        remark: null,

        area: null,
        website: null,
        mail: null,
        province:null,
        city:null,
        workTime: null,
        deptId: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        name: [
          { required: true, message: '名称不能为空', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '地址不能为空', trigger: 'blur' }
        ],
        area: [
          { required: true, message: '区域不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {

    tencentMapOk(record) {
      console.log(11111111111,record)
      this.form.lon = record.lng;
      this.form.lat = record.lat;
      this.form.address = record.address;
    },
    subjectLevelOneChanged(value){
      debugger
      //value就是一级分类的id值
      //先遍历所有的分类 里面包含一级和二级
      for (var i = 0; i <this.citydata.length; i++) {
        //每个一级分类
        var oneSubject=this.citydata[i]
        //判断：所有一级分类id和点击一级分类id是否一样
        if(value===oneSubject.id){  //===即比较值 还要比较类型
          //从一级分类中获取所有的二级分类
          this.subjectTwoList=oneSubject.children
          // if (!this.cityId){
          //   this.cityId= ''
          //   this.areaId=''
          //   this.subjectThreeList=this.subjectTwoList[0].children
          //   this.areaId=this.subjectTwoList[0].children[0].id
          // }
        }
      }
    },

    subjectLevelOneChanged1(value){
      debugger
      //value就是一级分类的id值
      //先遍历所有的分类 里面包含一级和二级
      for (var i = 0; i <this.citydata.length; i++) {
        //每个一级分类
        var oneSubject=this.citydata[i]
        //判断：所有一级分类id和点击一级分类id是否一样
        if(value===oneSubject.id){  //===即比较值 还要比较类型
          //从一级分类中获取所有的二级分类
          this.subjectTwoList=oneSubject.children
            this.cityId= ''
            this.areaId=''
        }
      }
    },

    subjectLevelTwoChanged(value){
      for (var j = 0; j <this.subjectTwoList.length; j++) {
        var otwoSubject=this.subjectTwoList[j]
        if(value===otwoSubject.id){
          this.subjectThreeList=otwoSubject.children
          // if (!this.areaId){
          //    this.areaId=''
          // }
        }
      }
    },

    subjectLevelTwoChanged1(value){
      for (var j = 0; j <this.subjectTwoList.length; j++) {
        var otwoSubject=this.subjectTwoList[j]
        if(value===otwoSubject.id){
          this.subjectThreeList=otwoSubject.children
          this.areaId=''
        }
      }
    },
    getCityData() {
      dataCity().then(response => {
        this.citydata = response.data;
      });
    },
    getSchoolDept(){
      listSchoolDept().then(response => {
        this.schoolDeptList = response.data;
      });
    },
    beforeUpload: function (file) {
      return new Promise((resolve, reject) => {
        var fileType = file.type;
        if (fileType.indexOf('image') < 0) {
          this.$message.warning('请上传图片');
          return reject(false);
        }
        resolve(true);
      });
    },
    handleChange(info) { //上传图片回调
      debugger
      let that = this;
      this.uploadLoading = true;

      ossUpload.ossUpload(info.file, function (fileName) {
        that.uploadLoading = false;
        console.log(fileName)
        that.form.pic = "https://ccjp.oss-cn-beijing.aliyuncs.com/"+fileName; //文件名
        console.log('1111', that.form.pic)
      });
    },

    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.areaId = null
      this.provinceId = null
      this.cityId = null
      this.form = {
        id: null,
        name: null,
        address: null,
        lon: null,
        lat: null,
        introduce: null,
        phone: null,
        remark: null,
        province: null,
        city: null,
        area: null,
        website: null,
        mail: null,
        pic: null,
        deptId: null,
        workTime: null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getCampus({"id":id}).then(response => {
        this.form = response.data
        this.areaId = this.form.area
        this.provinceId=this.form.province
        this.cityId=this.form.city
        this.subjectLevelOneChanged(this.provinceId)
        this.subjectLevelTwoChanged(this.cityId)
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      if (!this.areaId) {
        this.$warning({
          title:'提示',
          content:'请填选择区域再提交'
        });
        return;
      }
      this.$refs.form.validate(valid => {

        if (valid) {
          this.submitLoading = true
          this.form.area = this.areaId
          this.form.province=this.provinceId
          this.form.city=this.cityId

          if (this.form.id !== undefined && this.form.id !== null) {
            updateCampus(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addCampus(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
